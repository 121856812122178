<template>
    <div class="grid">
        <q-icon 
            v-show="!show_menu"
            class="no-desktop trigger-menu-button" 
            name="fas fa-chevron-right"
            color="primary"
            size="xl"
            @click="show_menu = true"
        />
        <q-icon 
            v-show="show_menu"
            class="no-desktop trigger-menu-button" 
            name="fas fa-chevron-left"
            color="primary"
            size="xl"
            @click="show_menu = false"
        />
        <nav :class="show_menu ? 'nav-visible' : ''">
            <div class="planungsmatrix">
                <label class="dashboard-nav-heading" v-html="resource[lang].planungsmatrix" />
                <router-link class="dashboard-nav-link" to="/dashboard/my-matrices" v-html="resource[lang].my_matrices" />
                <router-link class="dashboard-nav-link" to="/dashboard/shared-matrices" v-html="resource[lang].shared_matrices" />
            </div>
            <div class="learning-classes" v-if="learning_classes.length != 0 || create_learning_class">
                <label class="dashboard-nav-heading" v-html="resource[lang].learning_classes" />
                <router-link 
                    class="dashboard-nav-link"
                    v-for="(learning_class, index) in learning_classes"
                    :key="index"
                    :to="`/dashboard/learning-class/${learning_class.id}`" 
                    v-html="learning_class.name"
                />
                <button 
                    v-if="!all_learning_classes && !loading" 
                    class="dashboard-nav-link show-more" 
                    v-html="resource[lang].show_more" 
                    @click="getLearningClasses"
                />
                <router-link 
                    v-if="!loading && create_learning_class" 
                    class="dashboard-nav-link create-link" 
                    to="/dashboard/learning-class" 
                    v-html="resource[lang].create_learning_class" 
                />
            </div>
        </nav>
        <div class="main">
            <router-view :key="$route.fullPath" v-slot="{ Component }">
                <transition name="fade">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/dashboard-resource'

import { promiseGetRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
            learning_classes: [],
            learning_class_page: 1,
            all_learning_classes: false,

            loading: true,

            // responsiveness
            show_menu: false,

            // user abilities
            create_learning_class: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.getLearningClasses();
            this.getUserAbilities();
        },
        async getUserAbilities() {
            this.create_learning_class = await getAbility('create-learning-class', this.user);
        },

        // Requests
        getLearningClassesRequest() {
            return promiseGetRequest(`learning-classes?page=${this.learning_class_page}`)
        },

        // Functions
        async getLearningClasses() {
            this.$store.dispatch('loading', true)
            const response = await this.getLearningClassesRequest();
            this.$store.dispatch('loading', false)
            this.learning_classes = [...this.learning_classes, ...response.data]
            this.learning_class_page += 1
            if (response.data.length < 10) {
                this.all_learning_classes = true;
            }
            this.loading = false
        }

    },
    watch: {
        user: function() {
            this.getUserAbilities();
        },
    },
    // check if user is logged in before enter
    beforeRouteEnter(to, from, next) {
        const token = localStorage.getItem('jwt_token');

        if (token) {
            next()
        } else {
            next({name: 'Login'})
        }
    },
}
</script>

<style lang="scss" scoped>

nav { 
    
    a {
        display: block;
    }
    
    div > * + * {
        margin-block-start: 1rem;
    }

    label {
        margin-block-end: 2.5rem;
    }

    div {
        margin-block-end: 5rem;
    }
}

.create-link {
    color: var(--dark_grey);
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

.grid {
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
}

.show-more {
    cursor: pointer;
    color: var(--dark_grey);
    text-decoration: underline;
    text-decoration-thickness: 1px;

    background: none;
    border: none;
}

@media (max-width: 64rem) {

    nav {
        position: fixed;
        left: -25rem;

        padding-top: 2rem;
        padding-left: 5rem;
        margin-left: -5rem;
        background-color: var(--whit);
        border-right: solid 1px var(--dark_grey);
        border-bottom: solid 1px var(--dark_grey);
        border-top: solid 1px var(--dark_grey);

        width: 25rem;

        z-index: 1;

        transition: left 1s;

        max-height: 67vh;
        overflow-y: auto;
    }

    .nav-visible {
        left: 3rem;
    }

    .grid {
        display: block;
    }

    .trigger-menu-button {
        position: fixed;
        left: -0.5rem;
        top: 10rem;
        z-index: 1;
        background-color: var(--light_grey);
        padding: 1rem 1.5rem;
        border-radius: 10px;
        border: solid 1px var(--dark_grey);
    }
}

@media (max-width: 40rem) {
    .trigger-menu-button {
        font-size: 2rem !important;
        padding: 0.75rem 1rem;
        border-radius: 5px;
    }
}

</style>